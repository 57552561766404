import { ThemeContextProvider } from "@/main/contexts/ThemeContext";
import { UseApiProvider } from "@/main/contexts/apiContext";
import { UseModalProvider } from "@/main/contexts/modal";
import { StorageLocation } from "@/infra/cache/storageLocation";

interface ProvidersProps {
    children: JSX.Element | JSX.Element[] | string | string[];
}

export default function Providers({ children }: ProvidersProps) {
    const localStoraged = new StorageLocation();

    return (
        <UseApiProvider storageLocation={localStoraged}>
            <ThemeContextProvider>
                <UseModalProvider>{children}</UseModalProvider>
            </ThemeContextProvider>
        </UseApiProvider>
    );
}
