import imgAcao from "@/presentation/assets/icone/menu/acao.svg";
// import imgUser from "@/presentation/assets/icone/menu/user.svg";
import imgChat from "@/presentation/assets/icone/chat.svg";
import imgConversor from "@/presentation/assets/icone/conversor.svg";

export const paths = [
    {
        icone: imgAcao,
        path: "/acoes",
        title: "Ação",
    },
    {
        icone: imgChat,
        path: "/preciso-de-ajuda",
        title: "Preciso de ajuda",
    },
    {
        icone: imgConversor,
        path: "/conversor-cpf",
        title: "Conversor de CPF",
    },
];
