import { useHistory } from "react-router-dom";
import { Container as ButtonIcon } from "@/presentation/components/Icone";
import { Container as Button } from "@/presentation/components/Button";
import { useAction } from "@/main/hooks/action";
import imgLines from "@/presentation/assets/icone/lines.svg";
import imgBlocos from "@/presentation/assets/icone/blocos.svg";
import imgFiltro from "@/presentation/assets/icone/filtro.svg";
import { Container } from "./styles";

export function ButtonsHeaderActions() {
    const history = useHistory();
    const { activeGroup, setActiveGroup, setActiveFilter, activeFilter } =
        useAction();
    return (
        <Container>
            <div className="btn-actions">
                <button onClick={() => setActiveFilter(!activeFilter)}>
                    <ButtonIcon
                        className={`${activeFilter ? "active" : ""}`}
                        icone={imgFiltro}
                    />
                </button>
                <button>
                    <ButtonIcon
                        className={`${activeGroup ? "active" : ""}`}
                        icone={imgBlocos}
                        onClick={() => {
                            setActiveGroup(true);
                        }}
                    />
                </button>

                <button>
                    <ButtonIcon
                        data-testid="type-inline"
                        className={`${!activeGroup ? "active" : ""}`}
                        onClick={() => {
                            setActiveGroup(false);
                        }}
                        icone={imgLines}
                    />
                </button>
            </div>

            <Button onClick={() => history.push("nova/acao")}>
                Adicionar Ação
            </Button>
        </Container>
    );
}
