import { useState } from "react";
import { Text, Flex } from "alisson-application";
import { useDetailAction } from "@/main/hooks/detailAction";
import { QuantityList } from "@/presentation/components/QuantityList";

import { IOSSwitch } from "@/presentation/components/Switch";
import { useModal } from "@/main/contexts/modal";
import imgLiberarEstoque from "@/presentation/assets/icone/liberar-acao.svg";
// import imgLiberarRelatorio from "@/presentation/assets/icone/relatorio.svg";
import imgIndicadorCima from "@/presentation/assets/img/indicaro-cima.svg";
import { Container } from "./styles";
import { toast } from "react-toastify";
import { Loader } from "../Loader";

export function HeaderDetailAction() {
    const { setState, state } = useModal();
    const {
        stateHistory,
        custumize,
        findActionData,
        setFindActionData,
        information,
        fetchDetailVoucher,
        handleClick,
        calls,
        activeBtnVoucher,
    } = useDetailAction();

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingVoucher, setLoadingVoucher] = useState<boolean>(false);
    const blocked = findActionData.blocked
        ? parseInt(findActionData.blocked + "")
        : 0;
    const [active, setActive] = useState<boolean>(blocked === 0 ? true : false);

    async function handleActive() {
        setActive(!active);
        setLoading(true);

        try {
            const response = await custumize.actionCustumize({
                blocked: !active ? 0 : 1,
                id: stateHistory.id,
            });
            setFindActionData(information.formatFindById(response));
            toast.success(
                `Ação ${!active ? "Ativada" : "desativada"} com sucesso`
            );
            setLoading(false);
        } catch (error) {
            console.log(error);
            toast.error("Erro ao ativar ação");
            setLoading(false);
        }
    }

    async function handleRevoke() {
        setLoading(true);
        try {
            await information.revoke({
                id: stateHistory.id,
            });

            toast.success(`Ação estornada com sucesso`);
            setLoading(false);
        } catch (error) {
            console.log(error);
            toast.error("Erro ao estornar ação");
            setLoading(false);
        }
    }

    return (
        <Container>
            <QuantityList
                avaliable={stateHistory.avaliable}
                revoked={stateHistory.revoked}
                rewarded={stateHistory.rewarded}
                total={stateHistory.total}
                book_stock={findActionData.book_stock}
            />

            <div className="container-actions">
                <button
                    className={`btn-action-toogle ${
                        activeBtnVoucher ? "active" : ""
                    }`}
                    data-testid="btn-handleClick"
                    disabled={loading}
                    onClick={async () => {
                        setLoadingVoucher(true);
                        await fetchDetailVoucher();
                        if (calls >= 1) handleClick();
                        setLoadingVoucher(false);
                    }}
                >
                    {loadingVoucher ? (
                        <Loader height={15} width={15} />
                    ) : (
                        <img src={imgIndicadorCima} alt="" />
                    )}
                </button>

                <Flex alignItems="center">
                    <IOSSwitch
                        name="blocked"
                        value={active}
                        disabled={loading}
                        checked={active}
                        onChange={async () => {
                            if (blocked === 1) {
                                setState({
                                    ...state,
                                    alert: {
                                        active: true,
                                        onclick: async () => {
                                            await handleActive();
                                        },
                                        text: "Você deseja realmente ATIVAR esta Ação?",
                                    },
                                });
                            } else {
                                setState({
                                    ...state,
                                    alert: {
                                        active: true,
                                        onclick: async () => {
                                            await handleActive();
                                        },
                                        text: "Você deseja realmente DESATIVAR esta Ação?",
                                    },
                                });
                            }
                        }}
                    />

                    <Text ml="7px" as="h5">
                        Ativar
                    </Text>

                    {loading && (
                        <div className="loader">
                            <Loader width={15} height={15} />
                        </div>
                    )}
                </Flex>

                {blocked === 1 ? (
                    <button
                        onClick={() => {
                            setState({
                                ...state,
                                alert: {
                                    active: true,
                                    onclick: () => handleRevoke(),
                                },
                            });
                        }}
                    >
                        <img src={imgLiberarEstoque} alt="" />
                        <Text ml="10px" as="h5">
                            Liberar estoque
                        </Text>
                    </button>
                ) : (
                    <></>
                )}

                {/* <button onClick={() => handleOpen("report")}>
                    <img src={imgLiberarRelatorio} alt="" />
                    <Text ml="10px" as="h5">
                        Exportar relatório
                    </Text>
                </button>  */}
            </div>
        </Container>
    );
}
