import { Button } from "alisson-application";
import styled from "styled-components";

export const Container = styled(Button)`
    border: none;
    background-color: ${(props) =>
        props.variant ? "" : props.theme.colors.backgroundAlt};
    border-radius: 100px;
    height: 40px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: ${(props) => props.theme.fonts.md};
    text-align: center;
    width: 100%;
    color: ${(props) => props.theme.colors.white};
`;
