import { FieldProps } from "formik";
import { useDetailActionVoucher } from "@/presentation/components/DetailContent/hooks/detailActionVoucher";
import { ListGroup } from "@/presentation/components/ListGroup";
import { Values } from "./config";

interface FormListProps extends FieldProps<Values> {
    hideDeleted?: boolean;
}

export function FormList(props: FormListProps) {
    const { form, hideDeleted = false } = props;
    const { values } = form;
    const { dataMetrics } = useDetailActionVoucher();

    function handlechange(id: number) {
        const hasProduct = values.product_configuration_ids.find(
            (product: number) => product === id
        );

        if (hasProduct) {
            const update = values.product_configuration_ids.filter(
                (product: number) => product !== id
            );
            form.setFieldValue("product_configuration_ids", update);
        } else {
            form.setFieldValue("product_configuration_ids", [
                ...values.product_configuration_ids,
                id,
            ]);
        }
    }

    return (
        <div className="container-list-vouchers">
            {dataMetrics?.products
                ?.filter(product => {
                    const shouldHide = hideDeleted && product.deleted_at && product.deleted_at !== "";
                    return !shouldHide;
                })
                ?.map((product) => {
                    const checked = values?.product_configuration_ids?.includes(
                        product.id
                    );
                    
                    return (
                        <ListGroup
                            foto_capa={product.img_modal}
                            nome={product.name}
                            id={product.id}
                            key={product.id}
                            onChange={(id: number) => handlechange(id)}
                            checked={checked}
                        />
                    );
                })}
        </div>
    );
}
