import { UseDetailActionInformationProvider } from "./hooks/detailActionInformation";
import { UseDetailActionCustumizeProvider } from "./hooks/detailActionCustumize";
import { UseDetailActionVoucherProvider } from "./hooks/detailActionVoucher";
import { UseDetailActionUserProvider } from "./hooks/detailActionUser";
import { UseDetailActionReportProvider } from "./hooks/detailActionReport";

import { useDetailAction } from "@/main/hooks/detailAction";
import { DetailActionInformation } from "@/presentation/components/DetailActionInformation";
import { DetailActionUser } from "@/presentation/components/DetailActionUser";
import { DetailActionVoucher } from "@/presentation/components/DetailActionVoucher";
import { DetailActionCustomization } from "@/presentation/components/DetailActionCustomization";
import { DetailActionReport } from "@/presentation/components/DetailActionReport";
import imgLiberarRelatorio from "@/presentation/assets/icone/relatorio.svg";
import { useModal } from "@/main/contexts/modal";
import { Text } from "alisson-application";
import { Container } from "./styles";

export function DetailContent() {
    const { tabs } = useDetailAction();

    const { handleOpen } = useModal();

    return (
        <Container>
            <div className={`container-tab ${tabs[0].active ? "active" : ""}`}>
                <UseDetailActionInformationProvider>
                    <DetailActionInformation />
                </UseDetailActionInformationProvider>
            </div>
            <div className={`container-tab remove-padding ${tabs[1].active ? "active" : ""}`}>
                {tabs[1].viewer && (
                    <UseDetailActionCustumizeProvider>
                        <DetailActionCustomization />
                    </UseDetailActionCustumizeProvider>
                )}
            </div>
            <div className={`container-tab ${tabs[2].active ? "active" : ""}`}>
                {tabs[2].viewer && (
                    <UseDetailActionUserProvider>
                        <DetailActionUser />
                    </UseDetailActionUserProvider>
                )}
            </div>
            <div className={`container-tab ${tabs[3].active ? "active" : ""}`}>
                <h1></h1>
            </div>
            <div
                className={`container-tab ${tabs[4].active ? "active" : ""
                    }`}
            >
                {tabs[4].viewer && (
                    <UseDetailActionVoucherProvider>
                        <DetailActionVoucher />
                    </UseDetailActionVoucherProvider>
                )}
            </div>
            <div className={`container-tab ${tabs[5].active ? "active" : ""}`}>
                <button
                    onClick={() => handleOpen("report")}
                    className="btn-exportar-relatorios"
                >
                    <img src={imgLiberarRelatorio} alt="" />
                    <Text ml="10px" as="h5" className="text-exportar-relatorio">
                        Exportar relatório
                    </Text>
                </button>
                {tabs[5].viewer && (
                    <UseDetailActionReportProvider>
                        <DetailActionReport />
                    </UseDetailActionReportProvider>
                )}
            </div>
        </Container>
    );
}
