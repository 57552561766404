import { Route, Redirect } from "react-router-dom";
import { useApi } from "@/main/contexts/apiContext";
import { Menu } from "@/presentation/components/Menu";
import { routesProps } from "@/main/routes/types";
import { AccountModel } from "@/domain/models";

type PrivateRouteProps = routesProps;

export default function PrivateRoute(props: PrivateRouteProps) {
    const { isPrivate = false, path } = props;
    const { getStorage } = useApi();

    const rotasSemMenu = ["/", "/dashboard"];

    const token = getStorage<AccountModel>("access_token");

    if (path === "*") {
        return <Redirect to="/" from={path} />;
    }

    // redirecionar rotas inexistentes
    if (path === "*" || path === "/") {
        return <Redirect to="/" from={path} />;
    }

    // rota privada sem token
    if (isPrivate && !token) {
        return <Redirect to="/" from={path} />;
    }

    return (
        <>
            {!rotasSemMenu.includes(path) && <Menu />}
            <div className={`${!rotasSemMenu.includes(path) ? "content" : ""}`}>
                <Route {...props} />
            </div>
        </>
    );
}
