import { useState } from "react";
import isEqual from "lodash/isEqual";
import { Text } from "alisson-application";
import { Container as ButtonVoucher } from "@/presentation/components/ButtonVoucher";
import Select, { options } from "@/presentation/components/Select";
import { DrafEditor } from "@/presentation/components/Editor";
import { BoxVoucherModal } from "@/presentation/components/BoxVoucherModal";
import { useDetailActionVoucher } from "@/presentation/components/DetailContent/hooks/detailActionVoucher";
import { MetricsProducts, ActionProductsCustomizeParms } from "@/domain/models";
import { useModal } from "@/main/contexts/modal";
import deleteIcon from "@/presentation/assets/icone/deleteIcon.svg";
import restoreIcon from "@/presentation/assets/icone/restoreIcon.svg";

import { ActiveContainer, InactiveContainer } from "./styles";

import imgIndicadorCima from "@/presentation/assets/img/indicaro-cima.svg";

interface CardActionVoucherProps extends MetricsProducts {
    optionsObtainMmethods: options[];
    optionsTemplate: options[];
    actionId: number;
}

export function CardActionVoucher(props: CardActionVoucherProps) {
    const {
        img_modal,
        img_store,
        quantity_per_reward,
        optionsTemplate,
        optionsObtainMmethods,
        name,
        variation_id,
        product_id,
        actionId,
        obtain_method,
        template,
        template_image,
        know_more_text,
        disclaimer,
        added_to_group,
        confirm_choose_modal_text,
        confirm_choose_modal_body,
    } = props;
    const { handleOpen, state, setState } = useModal();
    const { updateProducts, handleSoftDeleteProduct, handleRestoreProduct } =
        useDetailActionVoucher();

    const [value, setValue] = useState<number | null>(quantity_per_reward);
    const initalValues = {
        action_id: actionId,
        product_id,
        variation_id: variation_id,
        name: name,
        obtain_method,
        template: template,
        quantity_per_reward: quantity_per_reward,
        disclaimer: disclaimer,
        added_to_group: added_to_group,
        confirm_choose_modal_text: confirm_choose_modal_text,
        confirm_choose_modal_body: confirm_choose_modal_body,
    };
    const [product, setproduct] =
        useState<ActionProductsCustomizeParms>(initalValues);
    const hasInitialize = Object.keys(product).length > 0;

    const hasObtainMmethods = optionsObtainMmethods.find(
        (method) => method.value === obtain_method
    );

    const [saibaMais, setSaibaMais] = useState<any>(false);

    const [showMessage, setShowMessage] = useState(false);

    const handleDivCaptionClick = () => {
        if (product.added_to_group === 1) {
            setShowMessage(true);
            setTimeout(() => setShowMessage(false), 6000);
        }
    };

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value.replace(/[^\d]+/g, "");

        if (!value) {
            setValue(0);
            setproduct((prevState) => ({
                ...prevState,
                quantity_per_reward: 0,
            }));
            return;
        }

        const valueNumber = parseInt(value);

        setValue(valueNumber);
        setproduct((prevState) => ({
            ...prevState,
            quantity_per_reward: valueNumber,
        }));
    }

    function handleChanges(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target;
        setproduct((prevState) => ({
            ...prevState,
            name: value,
        }));
    }

    function handleChangesTextModal(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target;
        setproduct((prevState) => ({
            ...prevState,
            confirm_choose_modal_text: value,
        }));
    }

    function handleChangesTextSegundoModal(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = e.target;
        setproduct((prevState) => ({
            ...prevState,
            confirm_choose_modal_body: value,
        }));
    }

    function handleDisclaimerChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target;
        setproduct((prevState) => ({
            ...prevState,
            disclaimer: value,
        }));
    }

    function handleChangesSaibaMais(valor: any) {
        setproduct((prevState) => ({
            ...prevState,
            know_more_text: valor,
        }));
    }

    const regex =
        /(?:|[^"]*[;\s])(background-color\s*:[^";]*)|(?:|[^"]*[;\s])(background\s*:[^";]*)/g;

    const Container = props.deleted_at ? InactiveContainer : ActiveContainer;

    return (
        <Container
            data-testid="card-action-voucher"
            deleted={!!props.deleted_at}
        >
            {props.deleted_at && (
                <div className="deleted-overlay">
                    Voucher removido dia {props.deleted_at.replace(/-/g, ".")}
                </div>
            )}
            <div className="container-detalhes">
                <div className="first-details-div">
                    <Text as="h5">ID Variação: {props.variation_id}</Text>
                    <Text as="h5">Nome do produto: {props.original_name}</Text>
                </div>
                {props.deleted_at === "" ? (
                    <Text
                        as="button"
                        onClick={() => {
                            setState({
                                ...state,
                                alert: {
                                    text: "Ao confirmar esta ação o voucher não estará mais disponível para os usuários que acessarem a loja",
                                    active: true,
                                    onclick: () =>
                                        handleSoftDeleteProduct(props.id),
                                },
                            });
                        }}
                    >
                        <div className="delete-div">
                            <img src={deleteIcon} alt="Remove Icon" />
                            <p>Remover Voucher</p>
                        </div>
                    </Text>
                ) : (
                    <Text
                        as="button"
                        onClick={() => {
                            setState({
                                ...state,
                                alert: {
                                    text: "Ao confirmar esta ação o produto estará disponível para os usuários que acessarem a loja",
                                    active: true,
                                    onclick: () =>
                                        handleRestoreProduct(props.id),
                                },
                            });
                        }}
                    >
                        <div className="restore-div">
                            <img src={restoreIcon} alt="Restore Icon" />
                            <p>Reativar Voucher</p>
                        </div>
                    </Text>
                )}
            </div>
            <div className="esq">
                <div
                    className="input-text"
                    style={{ height: showMessage ? "485px" : "440px" }}
                >
                    <div className="container-input container-field">
                        <Text as="h5" mb="10px">
                            Nome e valor
                        </Text>
                        <input
                            type="text"
                            data-testid="input-name"
                            value={product.name}
                            onChange={handleChanges}
                        />
                    </div>
                    <div className="container-input container-field">
                        <Text as="h5" mb="10px">
                            Texto modal confirmação
                        </Text>
                        <input
                            type="text"
                            data-testid="input-name"
                            value={
                                product.confirm_choose_modal_text
                                    ? product.confirm_choose_modal_text
                                    : ""
                            }
                            onChange={handleChangesTextModal}
                        />
                    </div>
                    <div className="container-input container-field">
                        <Text as="h5" mb="10px">
                            Texto 2 modal confirmação
                        </Text>
                        <input
                            type="text"
                            data-testid="input-name"
                            value={
                                product.confirm_choose_modal_body
                                    ? product.confirm_choose_modal_body
                                    : ""
                            }
                            onChange={handleChangesTextSegundoModal}
                        />
                    </div>
                    <div
                        className="container-input container-field"
                        onClick={handleDivCaptionClick}
                    >
                        <Text
                            as="h5"
                            pb="10px"
                            style={{
                                color:
                                    product.added_to_group === 1
                                        ? "gray"
                                        : "null",
                            }}
                        >
                            Legenda
                        </Text>
                        <div style={{ position: "relative" }}>
                            <input
                                type="text"
                                data-testid="input-disclaimer"
                                placeholder="Legenda"
                                value={
                                    product.disclaimer ? product.disclaimer : ""
                                }
                                onChange={handleDisclaimerChange}
                                disabled={product.added_to_group === 1}
                            />
                            {product.added_to_group === 1 && (
                                <div
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        cursor: "not-allowed",
                                    }}
                                    onClick={handleDivCaptionClick}
                                ></div>
                            )}
                        </div>
                        {showMessage && (
                            <Text as="h5" mt="10px" style={{ color: "red" }}>
                                Produto vinculado a um grupo, legenda editável
                                por ele.
                            </Text>
                        )}
                    </div>

                    <div className="container-field">
                        <Text as="h5" mb="10px">
                            Template
                        </Text>
                        <Select
                            options={optionsObtainMmethods}
                            initalValue={hasObtainMmethods}
                            label="Template"
                            onChange={(values) => {
                                setproduct((prevState) => ({
                                    ...prevState,
                                    obtain_method: values.value + "",
                                }));
                            }}
                        />
                    </div>

                    <div className="item container-input">
                        <Text as="h5" mb="10px" className="custom-padding">
                            Qtd. por resgate
                        </Text>

                        <input
                            type="tel"
                            data-testid="input-quantity"
                            value={value ? value : ""}
                            onChange={handleChange}
                            pattern="\d*"
                        />
                    </div>
                </div>
                <div className="container-input saiba-mais">
                    <p
                        className={`link-saiba-mais ${
                            saibaMais === props.id ? "active" : ""
                        } `}
                        onClick={() => {
                            if (saibaMais === props.id) {
                                setSaibaMais(false);
                            } else {
                                setSaibaMais(props.id);
                            }
                        }}
                    >
                        Saiba mais
                        <img src={imgIndicadorCima} alt="" />
                    </p>
                    <div
                        className={`container-saiba-mais ${
                            saibaMais === props.id ? "active" : ""
                        } `}
                    >
                        <DrafEditor
                            title="Saiba mais"
                            testid="know_more_text"
                            values={know_more_text ? know_more_text : ""}
                            onChange={(values) => {
                                const html = values.trim().replace(regex, "");

                                if (
                                    html.replace(/<.*?>/g, "").trim().length ===
                                    0
                                ) {
                                    handleChangesSaibaMais("");
                                } else {
                                    handleChangesSaibaMais(html);
                                }
                            }}
                            dimmed={props.deleted_at ? true : false}
                        />
                    </div>
                </div>

                <ButtonVoucher
                    onClick={() => {
                        handleOpen("alert");

                        setState({
                            ...state,
                            alert: {
                                active: true,
                                onclick: () => updateProducts(product),
                            },
                        });
                    }}
                    data-testid="button-save"
                    disabled={isEqual(product, initalValues) && hasInitialize}
                >
                    Salvar
                </ButtonVoucher>
            </div>

            <div className="dir">
                <BoxVoucherModal
                    img={template_image ? template_image : ""}
                    title="Template"
                    fileName="template_image"
                    height={400}
                    width={500}
                    arrayOptions={optionsTemplate}
                    optionSelected={template}
                    onSelected={(values) => {
                        setproduct((prevState) => ({
                            ...prevState,
                            template: values.value + "",
                        }));
                    }}
                    onChance={(file) => {
                        setproduct((prevState) => ({
                            ...prevState,
                            ...file,
                        }));
                    }}
                    dimmed={props.deleted_at ? true : false}
                />
                <BoxVoucherModal
                    img={img_modal}
                    title="Imagem modal"
                    fileName="img_modal"
                    height={400}
                    width={500}
                    onChance={(file) => {
                        setproduct((prevState) => ({
                            ...prevState,
                            ...file,
                        }));
                    }}
                    dimmed={props.deleted_at ? true : false}
                />
                <BoxVoucherModal
                    img={img_store}
                    title="Imagem loja"
                    fileName="img_store"
                    height={400}
                    width={500}
                    onChance={(file) => {
                        setproduct((prevState) => ({
                            ...prevState,
                            ...file,
                        }));
                    }}
                    dimmed={props.deleted_at ? true : false}
                />
            </div>
        </Container>
    );
}
