import {
    PageLogin,
    PageDashboard,
    PageAction,
    PageNewAction,
    PageDetailAction,
    PageHistoryActionLoad,
    PageNeedHelp,
    PageConversor,
    PageProcessandoSync
} from "@/main/pages";

import RegisterAuthenticator from "../pages/Auth/registerAuthenticator";
import ValidateAuthenticator from "../pages/Auth/validateAuthenticator";

import { routesProps } from "./types";

export const routes: routesProps[] = [
    { path: "/", exact: true, component: PageLogin, isPrivate: false },
    {
        path: "/dashboard",
        exact: true,
        component: PageDashboard,
        isPrivate: true,
    },

    { path: "/acoes", exact: true, component: PageAction, isPrivate: true },
    {
        path: "/conversor-cpf",
        exact: true,
        component: PageConversor,
        isPrivate: true,
    },
    {
        path: "/resgates-processando-sync",
        exact: true,
        component: PageProcessandoSync,
        isPrivate: true,
    },
    {
        path: "/preciso-de-ajuda",
        exact: true,
        component: PageNeedHelp,
        isPrivate: true,
    },

    {
        path: "/nova/acao",
        exact: true,
        component: PageNewAction,
        isPrivate: true,
    },
    {
        path: "/detalhe/acao/:id",
        exact: true,
        component: PageDetailAction,
        isPrivate: true,
    },
    {
        path: "/detalhe/acao/historico/:id",
        exact: true,
        component: PageHistoryActionLoad,
        isPrivate: true,
    },
    { 
        path: "/auth/register-authenticator",
        exact: true,
        component: RegisterAuthenticator,
        isPrivate: false,
    },
    { 
        path: "/auth/validate-authenticator",
        exact: true,
        component: ValidateAuthenticator,
        isPrivate: false,
    },
    {
        path: "*",
        component: PageLogin,
        isPrivate: true,
    },


];
